// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap-select");
import {} from "jquery";
import {} from "jquery-ujs";

import "../stylesheets/application";
import "./bootstrap_custom.js";
import "trix";
import { Luminous } from "luminous-lightbox";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function () {
  $(".dropdown-menu a.dropdown-item").click(function () {
    $(".dropdown-menu.show").removeClass("show");
  });

  $("select").selectpicker();
  $('[data-toggle="tooltip"]').tooltip();
  new Luminous(document.querySelector(".lightbox-image"));
});

$(document).ready(function () {
  $(document).ajaxSuccess(function () {
    $("form[data-remote] button[data-form-cancel]").click(function (e) {
      e.preventDefault();
      var target = $(e.target);
      // check if this is a new model, in that case just remove everything
      var new_model = target
        .closest(".model-form")
        .parent()
        .closest(target.data("form-cancel"));
      if (
        new_model.length > 0 &&
        target.data("form-cancel").startsWith("#new_")
      ) {
        new_model.remove();
      } else {
        var container = target.closest(".model-form").parent();
        container.find(".model-view").show();
        container.find(".model-form").hide();
      }
    });
  });
});
